<template>
  <UiTourDriver
    ref="driver"
    :tour="TOURS_IDS.QUICK_FILTERS"
    :highlight
    :steps
    :type="TOURS_TYPES.NEW_FEATURE"
    @destroyed="useMittEvent('tour:quickFilters')"
  />
</template>

<script setup lang="ts">
import type { DriveStep } from 'driver.js'
import type { UiTourDriver } from '#build/components'
import { TOURS_IDS, TOURS_TYPES } from '~/constants'

const driver = ref<InstanceType<typeof UiTourDriver> | null>(null)

onMounted(() => {
  useMittListen('tour:quickFilters', () => {
    if (driver.value?.driverObj) {
      driver.value.startTour()
    }
  })
})

const highlight = ref<DriveStep>({
  element: '#quick-filters',
  popover: {
    title: 'Quick filters',
    description: `Introducing you a new feature for filtering leads! More complex conditions for finding the right leads are now available😉`,
    nextBtnText: 'Learn more',
    side: 'bottom',
  },
})

const steps = computed<DriveStep[]>(() => [
  {
    element: '#filters',
    popover: {
      title: '1/3 Quick filters',
      description: `Use them as ready-made presets or combine them with the main filters to get more accurate information and speed up your workflow.`,
      align: 'start',
      side: 'bottom',
      onNextClick: () => {
        const quickFiltersButton = document.querySelector<HTMLElement>('#quick_filters_activator')
        if (!quickFiltersButton) return
        quickFiltersButton.click()
        setTimeout(() => {
          driver.value?.driverObj?.moveNext()
        }, 300)
      },
    },
  },
  {
    element: '#quick-filters_menu',
    popover: {
      title: '2/3 Quick filters',
      description: `Now you can easily filter 'Qualified by SDR' and 'Qualified w.a.' right in the main Pipeline table — no need to switch between separate tabs!`,
      align: 'start',
      onNextClick: () => {
        driver.value?.driverObj?.moveNext()
      },
    },
  },
  {
    element: '#show_columns',
    popover: {
      title: '3/3 Quick filters',
      description: `With more columns now available in the table, you can easily manage them through the settings.`,
      onNextClick: () => {
        driver.value?.driverObj?.destroy()
      },
    },
  },
])

onUnmounted(() => {
  useMittRemove('tour:quickFilters')
})
</script>

<style scoped lang="scss">
:deep() {
  .driver-popover-next-btn {
    width: 100% !important;
  }
}
</style>
